import React from 'react'

import ListCols from './ListCols'

const Editorials = (props) => {
  return (
    <div>
      <h2 style={{marginBottom: "4px"}}>Editorials</h2>
      <ListCols />
    </div>
    )
}

export default Editorials