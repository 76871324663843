import React, {useState, useEffect} from 'react'
import axios from 'axios'
import '../App.css'
import parse from 'html-react-parser'
import waiting from './loading.gif'

const ListCols = () => {

  const [columns, setColumns] = useState([]);
  const [column,  setColumn ] = useState("Body");
  const [Title,   setTitle  ] = useState("Title");
  const [sTitle,  setSTitle ] = useState("Subtitle");
  const [show,    setShow   ] = useState(false);
  const [wait,    setWait   ] = useState(true);

  useEffect( () => {
    const fetchcols = async () => {
    const response = await axios.get("http://LPinter.com/Test/getData");
    const theData  = await response.data;
    setColumns(theData);
    };
    fetchcols();
  }, []);

  useEffect(() => {
    setWait(false);
  }, [column]);

  const showArticle = async (id) => {
    const cid = id.toString();
    // const s1 = "http://LPinter.com/Test/IncCounter.aspx?id=" + cid;
    // await axios.get(s1);
    const s2 = "http://LPinter.com/Test/GetData.aspx?id=" + cid;
    const response =  await axios.get(s2);
    const theData  =  await response.data;
    const one = theData[0];
    setColumn(parse(one.Body));
    setTitle (one.Title);
    setSTitle(one.subtitle);
    setShow(true);
  }; 

  return ( 
    <table>
      <tbody style={{verticalAlign: "top"}}>
       <tr>
        <td>
         <div style={{height: "610px", verticalAlign: "top", overflowY: "scroll"}}>
          <table>
            <tbody>
            {columns.map(column => (
              <tr key={column.ID}>
                <td>
                <p className="btn" 
                   key={column.ID} 
                   title={column.subtitle}
                   onClick={() => showArticle(column.ID)}>
                {column.Title}
                </p>
              </td>
             </tr>))}
            </tbody>
          </table>
         </div>
        </td>
        <td style={{verticalAlign: "Top", backgroundColor: "#bdcce9"}}>
          <div style={{marginLeft: "5px", topMargin: "2px"}}>
           <div id="column" style={{"verticalAlign": "Top"}} >
              {show ? <h1>{Title}</h1> : null }
              {show ? <h3 className="subtitle">{sTitle}</h3> : null }
              {show ? <hr /> : null }
              {wait ? <img src={waiting} alt="loading" /> : null }
              {show ? <div>{column}</div> : null }
              {show ? <p style={{fontFamily: "Wingdings",textAlign: "right"}}>&#x2751;&#x2751;&#x2751;</p> : null }
            </div>
          </div>
        </td>
       </tr>
      </tbody>
    </table>
  );
}

export default ListCols