import './App.css';
import React, { useState } from 'react'
import About      from './components/About'
import Home       from './components/Home'
import Editorials from './components/Editorials'
// import Search     from './components/Search'
import BellCurve from './BellCurve.jpg'

function App() {
  const [showAbout, setShowAbout] = useState(false); 
  const [showHome,  setShowHome ] = useState(false); 
  const [showSrch,  setShowSrch ] = useState(false); 
  const [showOpEd,  setShowOpEd ] = useState(true); 

  function AboutOff() { setShowAbout(false); }
  function AboutOn()  { setShowAbout(true); setShowOpEd(false); setShowHome(false); setShowSrch(false) }

  function OpEdOff()  { setShowOpEd(false); }
  function OpEdOn()   { setShowAbout(false); setShowOpEd(true); setShowHome(false); setShowSrch(false) }

  function SrchOff()  { setShowSrch(false); }
  function SrchOn()   { setShowAbout(false); setShowOpEd(false); setShowHome(false); setShowSrch(true)  }

  function HomeOff()  { setShowHome(false); }
  function HomeOn()   { setShowAbout(false); setShowOpEd(false); setShowHome(true); setShowSrch(false) }

  function Invite()   { document.location="http://LPinter.com/Invitations/Invite"; }

  return (
    <div className="App">
      <header className="App-header">
        <div content-align="center" style={{backgroundColor: "#bdcce9"}}>
          <table width="525px">
            <tbody>
              <tr>
                <td><h1>Les Pinter</h1></td>
                <td valign="bottom" width="200px" >
                  <img src={BellCurve} alt="logo" height="60px" align="right" title="IQ distribution of American Voters"></img></td>
              </tr>
            </tbody>
            </table>
          
          <button title="Show the HOME page" onClick={HomeOn}>Home</button>
          <button title="Show all titles" onClick={OpEdOn}>All editorials</button>
          {/* <button title="Click to search for columns or titles containing keywords" onClick={SrchOn}>Full-text search</button> */}
          <button title="Show the ABOUT page" onClick={AboutOn}>About</button>
          <button title="Register for Les's Post-Election Victory Party" onClick={Invite}>Request an Invitation</button>
        </div>
      </header>
      <div className="content">
        {showAbout ? <About       off={AboutOff} /> : null }
        {showHome  ? <Home        off={HomeOff}  /> : null }
        {showOpEd  ? <Editorials  off={OpEdOff}  /> : null }
        {/* {showSrch  ? <Search      off={SrchOff}  /> : null } */}
      </div>
      <footer style={{textAlign: "center", verticalAlign: "bottom"}}>Copyright &copy; 2024 Les Pinter &bull; All rights reserved</footer>    
    </div>
  );
}

export default App;