import React from 'react'
import logo from '../Les2016.gif'
import pict from '../1965.jpg'

const About = (props) => {
  return (
    <div onClick={props.off}>
      <h2>About Les Pinter</h2>
      <p style={{height: "2px"}}>&nbsp;</p>
      <img 
       style={{width: "22%", border: "2px solid black", borderRadius: "15px", boxShadow: "3px 3px 3px #333", marginLeft: '50px', height: "22%"}} 
       src={pict} alt="1965"
       title="Les Pinter, Teotihuacán, Mexico, 1965" />
      <img 
       style={{width: "15%", border: "2px solid black", borderRadius: "15px", boxShadow: "3px 3px 3px #333", marginLeft: '50px'}} 
       src={logo} alt="2022"
       title="Les Pinter, Brisbane, QLD, 2022" />
      <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         Les in 1965 
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         Les in 2022</h3>
      <p>Les lives with his wife Ying-Ying Chang-Pinter on a ranch in the foothills of the Sierra Madre near Springville, California. He has written 8 books and over 265 technical articles about programming. </p>
      <p>You can email Les at <a href="mailto: Les@LPinter.com?subject=Mail%20from%20website">Les@LPinter.com</a>. </p>
    </div>
  )
}

export default About