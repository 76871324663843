import React from 'react'

const Home = () => {
  return (
    <div>
      <h2>Home</h2>
      <p>Les Pinter was on track to earn a Ph.D. in economics at Rice University in Houston when his young son was stricken with spinal cord cancer. He eventually completed the coursework for a Ph.D. in finance, but not the dissertation. But he continued writing other things, including eight books, over 260 technical journal articles on Information Technology, and hundreds of editorials for four different IT publications.</p>
      <p>In 1980, Les sold the Magic Wand, the program that became Microsoft Word, to a 24-year-old Bill Gates. Since then, Les has worked as a writer and a software developer.He has spoken at numerous information technology events around the world, giving talks in English, Spanish, Portuguese, French, Russian, and bad German.</p>
      <p>This website is provided as an index of Les Pinter's weekly OpEd column in the Porterville Recorder, which began in April of 2021. His most recent book (<i>HTTPV: How A Grocery Shopping Website Can Save America</i>) is available on Amazon.com.</p>
    </div>
  )
}

export default Home